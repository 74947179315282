<!-- This file is generated by @sveltejs/kit — do not edit it! -->
<svelte:options runes={true} />
<script>
	import { setContext, onMount, tick } from 'svelte';
	import { browser } from '$app/environment';

	// stores
	let { stores, page, constructors, components = [], form, data_0 = null, data_1 = null, data_2 = null, data_3 = null, data_4 = null, data_5 = null } = $props();

	if (!browser) {
		setContext('__svelte__', stores);
	}

	if (browser) {
		$effect.pre(() => stores.page.set(page));
	} else {
		stores.page.set(page);
	}
	$effect(() => {
		stores;page;constructors;components;form;data_0;data_1;data_2;data_3;data_4;data_5;
		stores.page.notify();
	});

	let mounted = $state(false);
	let navigated = $state(false);
	let title = $state(null);

	onMount(() => {
		const unsubscribe = stores.page.subscribe(() => {
			if (mounted) {
				navigated = true;
				tick().then(() => {
					title = document.title || 'untitled page';
				});
			}
		});

		mounted = true;
		return unsubscribe;
	});

	const Pyramid_5=$derived(constructors[5])
</script>

{#if constructors[1]}
	{@const Pyramid_0 = constructors[0]}
							<!-- svelte-ignore binding_property_non_reactive -->
							<Pyramid_0 bind:this={components[0]} data={data_0} {form}>
								{#if constructors[2]}
									{@const Pyramid_1 = constructors[1]}
															<!-- svelte-ignore binding_property_non_reactive -->
															<Pyramid_1 bind:this={components[1]} data={data_1} {form}>
																{#if constructors[3]}
																	{@const Pyramid_2 = constructors[2]}
																							<!-- svelte-ignore binding_property_non_reactive -->
																							<Pyramid_2 bind:this={components[2]} data={data_2} {form}>
																								{#if constructors[4]}
																									{@const Pyramid_3 = constructors[3]}
																															<!-- svelte-ignore binding_property_non_reactive -->
																															<Pyramid_3 bind:this={components[3]} data={data_3} {form}>
																																{#if constructors[5]}
																																	{@const Pyramid_4 = constructors[4]}
																																							<!-- svelte-ignore binding_property_non_reactive -->
																																							<Pyramid_4 bind:this={components[4]} data={data_4} {form}>
																																								<!-- svelte-ignore binding_property_non_reactive -->
																																										<Pyramid_5 bind:this={components[5]} data={data_5} {form} />
																																							</Pyramid_4>
																																	
																																{:else}
																																	{@const Pyramid_4 = constructors[4]}
																																	<!-- svelte-ignore binding_property_non_reactive -->
																																	<Pyramid_4 bind:this={components[4]} data={data_4} {form} />
																																	
																																{/if}
																															</Pyramid_3>
																									
																								{:else}
																									{@const Pyramid_3 = constructors[3]}
																									<!-- svelte-ignore binding_property_non_reactive -->
																									<Pyramid_3 bind:this={components[3]} data={data_3} {form} />
																									
																								{/if}
																							</Pyramid_2>
																	
																{:else}
																	{@const Pyramid_2 = constructors[2]}
																	<!-- svelte-ignore binding_property_non_reactive -->
																	<Pyramid_2 bind:this={components[2]} data={data_2} {form} />
																	
																{/if}
															</Pyramid_1>
									
								{:else}
									{@const Pyramid_1 = constructors[1]}
									<!-- svelte-ignore binding_property_non_reactive -->
									<Pyramid_1 bind:this={components[1]} data={data_1} {form} />
									
								{/if}
							</Pyramid_0>
	
{:else}
	{@const Pyramid_0 = constructors[0]}
	<!-- svelte-ignore binding_property_non_reactive -->
	<Pyramid_0 bind:this={components[0]} data={data_0} {form} />
	
{/if}

{#if mounted}
	<div id="svelte-announcer" aria-live="assertive" aria-atomic="true" style="position: absolute; left: 0; top: 0; clip: rect(0 0 0 0); clip-path: inset(50%); overflow: hidden; white-space: nowrap; width: 1px; height: 1px">
		{#if navigated}
			{title}
		{/if}
	</div>
{/if}